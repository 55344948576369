import React from "react";
import { graphql } from "gatsby";

const Event = ({ data }) => <>Event template</>;

export default Event;

export const query = graphql`
  query event($id: String!) {
    strapiEvent(id: { eq: $id }) {
      id
    }
  }
`;
